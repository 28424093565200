export  default{
    setSingleWorker(state,payload){
        state.singleworker=payload.data;
    },
    setWorker(state,payload){
        state.workers=payload.data;
        state.pagination=payload.pagination;
        state.paginationText=payload.pagination_text;   
        state.active=payload.active;                    
    },
    setProjectOption(state,payload){
        state.projectOption=payload.data;
    },
    setItemOption(state,payload){
        state.itemtOption=payload.data;
    },
};