import { createApp } from 'vue'
import App from './App.vue'
import BaseCard from './components/UI/BaseCard.vue'
import BaseSpinner from './components/UI/BaseSpinner.vue'
import BaseDialoge from './components/UI/BaseDialog.vue'
import BaseButton from './components/UI/BaseButton.vue'
import SearchAction from './components/UI/SearchAction.vue'
import TheHeader from './components/layout/TheHeader.vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'popper.js';
import store from './API/index.js'

import router from './routers.js'
/*import $ from "jQuery";
window.jQuery = $;
$=window.jQuery;*/

//Global axiox path
import axios from 'axios';
//axios.defaults.baseURL = 'http://estimate.dechome.in/Database/';
//axios.defaults.baseURL = 'http://localhost/estimate/src/database/';
axios.defaults.baseURL = '/Database/';


createApp(App).use(router).use(store).component('the-header',TheHeader).component("base-card",BaseCard).component('base-spinner',BaseSpinner).component('base-dialog',BaseDialoge).component("base-button",BaseButton).component("search-action",SearchAction).mount('#app')
