import { createRouter, createWebHistory } from "vue-router";
import store from './API';
const routes = [
  {
    name: "Login",
    component: () => import("./components/Auth/Login.vue"),
    path: "/",
    meta: { requiresUnAuth: true }
  },
  {
    name: "dash",
    component:() => import("./components/admin/DashBoard.vue"),
    path: "/dash",
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    redirect: "/",
  },
  {
    name: "Client",
    component:() => import("./components/admin/Client.vue"),
    path: "/client",
    meta: { requiresAuth: true }
  },
  {
    name: "Project",
    component:() => import("./components/project/Project.vue"),
    path: "/project",
    meta: { requiresAuth: true }
  },
  {
    name: "Purchase",
    component:() => import("./components/Purchase/Purchase.vue"),
    path: "/purchase",
    meta: { requiresAuth: true }
  },
  {
    name: "Payment",
    component:() => import("./components/payment/Payment.vue"),
    path: "/payment",
    meta: { requiresAuth: true }
  },
  {
    name: "Worker",
    component:() => import("./components/worker/Worker.vue"),
    path: "/worker",
    meta: { requiresAuth: true }
  },
  {
    name: "Report",
    component:() => import("./components/Leadger/TheReport.vue"),
    path: "/report",
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(function(to,_,next){
  store.dispatch('tryLogin');
  /*var Today = new Date();
    var today = Today.getTime();
    var mintues =  2 * 60000  ;
    const lastUseTime = localStorage.getItem('lastuse');
    if(!store.getters.isAuthenticate){
      /*var useMintues = (today - lastUseTime)/mintues;
      const timeout = Math.round(useMintues);
        if(timeout>0){
          store.dispatch('AutoLogout')
        }else{      
          localStorage.setItem('lastuse',today);
        }
      
      next();
    }*/
  if(!store.getters.isAuthenticate && to.meta.requiresAuth){
    next('/');
  }else 
  if(store.getters.isAuthenticate ==3){
   if(to.fullPath=='/client' || to.fullPath=='/project' || to.fullPath=='/purchase' || to.fullPath=='/payment' || to.fullPath=='/worker' ||to.fullPath=='/report'){
     next('/')
   }
  }
   next();
  
})

export default router;
/*router.beforeEach(function(to, _, next) {
    var Today = new Date();
    var today = Today.getTime();
    var mintues =  2 * 60000  ;
    const lastUseTime = localStorage.getItem('lastuse');
    alert(!store.getters.isAuthenticate)
    if(!store.getters.isAuthenticate){
      var useMintues = (today - lastUseTime)/mintues;
      const timeout = Math.round(useMintues);
      alert('come')
        if(timeout>0){
          alert('AutoLogout')
          store.dispatch('AutoLogout')
        }else{      
          localStorage.setItem('lastuse',today);
        }
      
      next();
    }else {
      next()
    }    
});*/

