<template>

        <div class="col-sm-6 col-md-6"><h1>{{heading}}</h1></div>
        <div class="col-sm-12 col-md-6 text-center p-0 m-0">
            <form class="d-flex">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" @keyup="searchlist" v-model.trim="search" list="projects">
                    <datalist id="projects" class="form-control form-control-sm" style="display:none">
                        <option 
                        v-for="option in ProjectOption"
                        :key="option.int_project_id"
                        :value="option.txt_project_name"
                        :data-value="option.int_project_id"
                    />
                    </datalist>
                <base-button  mode="outline" @click.prevent="searchlist">Search</base-button>
                <base-button mode="button" type="button" ><a  target="_blank" @click="getpdf()" id="pdf_btn" ><strong>Download</strong></a></base-button>
            </form>   
        </div>
</template>
<script>
import BaseButton from './BaseButton.vue'
export default {
  components: { BaseButton },
    emits:['searchlist'],
    props:['heading'],
    data(){
        return{
            search:'',
            ProjectOption: null,
        }
    },
    methods:{
        searchlist(){
            this.$emit('searchlist',1,this.search);
        },
        getpdf(){
        var client_option='';
        var year='';
        var month='';
        var gst_type='';
        var gst_type_2='';
        var platform_option='';
        var dt_from='';
        var dt_to='';
        var uid='';
        var project_id='';
        var project_name='';
            /*try{
                this.$store.dispatch('pdf/getpdf',{page_name:this.heading});
            }catch(error){
               console.log('error'+error)
            }*/
             //window.open(`http://localhost/estimate/src/Database/pdf_download.php?project_id=${project_id}&project_name=${project_name}&uid=${uid}&page_name=${this.heading}&client_option=${client_option}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}`);
             window.open(`/Database/pdf_download.php?project_id=${project_id}&project_name=${project_name}&uid=${uid}&page_name=${this.heading}&client_option=${client_option}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}`);
 
        },setOption() {
            this.ProjectOption = this.$store.getters["report/getProjectOption"];
        },
        getOption() {
            if(this.heading=='Payment' || this.heading=='Purchase'){
            this.$store.dispatch("report/getOption", { id: 0 });
            this.isLoading = true;
             setTimeout(() => {
                  this.setOption();
                  this.isLoading = false;
            }, 1500);
            }
        },
    },created(){
        this.getOption();
    }
}
</script>