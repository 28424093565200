export default{
    getPayments(state){
        return state.payment;
    },
    getActive(state){
        return state.active;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    getSinglePayment(state){
        return state.singlepayment;
    },
    getClientOption(state){
        return state.clientOption;
    },
    getProjectOption(state){
        return state.projectOption;
    }
}