import axios from 'axios';
export default{
      async getProjectReport(context, payload) {
        var id = payload.id;
        await axios
          .get(`admin/report.php?action=getProjectReport&id=${id}`)
          .then((res) => {
            if (!res.data.successmsg || res.data.error) {
              const error = new Error(error || "Something Wrong try again later.");
              throw error;
            } else {
              var data = res.data.details;
              context.commit("setProjectReport", { data: data });
            }
          })
          .catch((error) => {
            console.log(error);
            error = new Error(error || "Failed to authenticate.");
            throw error;
          });
      },
      async getOption(context,payload){
        var id=payload.id;
        await  axios.get(`admin/purchase.php?action=getoption&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var projectdata= res.data.projectdata;
                context.commit("setProjectOption", { dataop: projectdata })
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
};