export default{
    getProjects(state){
        return state.projects;
    },
    getActive(state){
        return state.active;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    getSingleProject(state){
        return state.singleproject;
    },
    getOption(state){
        return state.clientOption;
    },
    getDelMessage(state){
        return state.delMessage;
    }
}