import axios from "axios";
export default {
  async login(context, payload) {
    var role;
    var id;
    var name;
    const currentTime = new Date().getTime();
    var data = new FormData();
    data.append("userid", payload.userid);
    data.append("password", payload.password);
    await axios
      .post(`login.php?action=login`, data)
      .then((res) => {
        if (res.data.data.length) {
          role = res.data.data[0].int_role_type;
          id = res.data.data[0].int_user_id;
          name = res.data.data[0].txt_name;
          context.commit("setUser", { role: role, id: id, name: name });
          localStorage.setItem("userId", id);
          localStorage.setItem("db_role", role);
          localStorage.setItem("userName", name);
          localStorage.setItem("lastuse", currentTime);
        } else {
          const error = new Error("Failed to authenticate.");
          throw error;
        }
        if (res.data.error) {
          const error = new Error("Failed to authenticate.");
          throw error;
        }
      })
      .catch((error) => {
        error = new Error(error || "Failed to authenticate.");
        throw error;
      });
  },
  tryLogin(context) {
    const userId = localStorage.getItem("userId");
    const role = localStorage.getItem("db_role");
    const name = localStorage.getItem("userName");
    context.commit("setUser", { role: role, id: userId, userName: name });
  },
  logout(context) {
    localStorage.removeItem("db_role");
    localStorage.removeItem("lastuse");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    context.commit("setUser", { role: null, id: null, name: null });
  },
  AutoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
    alert("Auto Logout");
  },
 
  
};
