import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default{
    state(){
        return{
            userId:null,
            dbRole:null,
            uname:null,
            didAutoLogout:false,
            time:1,
        }
    },
    getters,
    mutations,
    actions,
}