export default{
    setClient(state,payload){
        state.clients=payload.data;
        state.pagination=payload.pagination;
        state.paginationText=payload.pagination_text;
        state.active=payload.active;     
    },
    setSingleClient(state,payload){
        state.singleClinet=payload.data;
    }
}