export default{
    setPurchase(state,payload){
        state.purchase=payload.data;
        state.pagination=payload.pagination;
        state.paginationText=payload.pagination_text;  
        state.active=payload.active;         
    },
    setSinglePurchase(state,payload){
        state.singlepurchase=payload.data;
    },
    setItemOption(state,payload){
        state.itemtOption=payload.data;
    },
    setProjectOption(state,payload){
        state.projectOption=payload.data;
    },
    delMessage(state,payload){
        
        state.delMessage=payload.data;
    }
}