export default{
    setPayment(state,payload){
        state.payment=payload.data;
        state.pagination=payload.pagination;
        state.paginationText=payload.pagination_text;   
        state.active=payload.active;         
    },
    setSinglePayment(state,payload){
        state.singlepayment=payload.data;
    },
    setClientOption(state,payload){
        state.clientOption=payload.data;
    },
    setProjectOption(state,payload){
        state.projectOption=payload.data;
    }
}