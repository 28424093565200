<template>
    <button v-if="!link" :class="mode" class="btn-sm">
        <slot></slot>
    </button>
    <router-link v-else :to="to" :class="mode">
        <slot></slot>
    </router-link>
</template>
<script>
export default {
    props:{
        mode:{
            type:String,
            required:false,
            default:null
        },
        link:{
            type:Boolean,
            required:false,
            default:false,
        },
        to:{
            type:String,
            required:false,
            default:'/'
        }
    }
}
</script>
<style scoped>
a,button{
    text-decoration: none;
    padding:0.75rem 1.5rem;
    font:inherit;
    background-color: var(--bluenavy);;
    border:1px solid var(--bluenavy);;
    color: white;
    cursor: pointer;
    border-radius: 30px;
    margin-left: 0.5rem;
    display: inline-block;
}

a:hover,
a:active,
button:active,
button:hover{
    color:#ccc;
}
.btn{
    background-color:var(--bluenavy);;
    color:#fff;
    border: 2px solid #BCBABE;
}
.flat{
    background-color: transparent;
    color:var(--bluenavy);;
    border:none;
}

.outline{
    background-color: transparent;
    color:var(--bluenavy);;
    border:2px solid var(--bluenavy);;
}
.icon{
    color:white;
}
.outline:hover,.outline:active{
    background-color: var(--bluenavy);;
    border:2px solid #BCBABE;
    color:#fff;   
}
.btn:hover,
.btn:active,
.flat:hover,
.flat:active{
    background-color: #BCBABE !important;
    border:2px solid var(--bluenavy) !important;
    color:var(--bluenavy) !important;
}
@media only screen and (max-width: 768px) {
  button {
    margin-left: 0.2rem;
    padding:0.50rem 0.80rem;
  }
}
</style>