export default{
    setProject(state,payload){
        state.projects=payload.data;
        state.pagination=payload.pagination;
        state.paginationText=payload.pagination_text;  
        state.active=payload.active;                
    },
    setSingleProject(state,payload){
        state.singleproject=payload.data;
    },
    setClientOption(state,payload){
        state.clientOption=payload.data;
    },
    delMessage(state,payload){
        state.delMessage=payload.data;
    }
}