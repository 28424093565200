import axios from 'axios';
export default{
  async getWorker(context,payload){
    var pn=payload.page_no;
    var option=payload.option;
    await  axios.get(`admin/worker.php?action=getworkers&option_id=${option}&page_no=${pn}`)
    .then((res) => {
          //console.log(res.data);
            var data= res.data.details;
            var pagination=res.data.pagination;
            var pagination_text = res.data.pagination_text;
            var active= res.data.active;
            context.commit('setWorker',{data:data,pagination:pagination,pagination_text:pagination_text,active:active})
    })
    .catch((error) => {console.log(error); 
         error = new Error(error ||'Failed to authenticate.');
        throw error;      
    })
},
      async addworker(context, payload) {
        var data = new FormData();
        data.append("name", payload.name);
        data.append("phone", payload.phone);
        data.append("email", payload.email);
        data.append("password", payload.password);
        await axios
          .post(`admin/worker.php?action=addworker`, data)
          .then((res) => {
            if (!res.data.successmsg || res.data.error) {
              const error = new Error("Something Wrong try again later.");
              throw error;
            }
          })
          .catch((error) => {
            error = new Error(error || "Failed to authenticate.");
            throw error;
          });
      },
      async getsingleworker(context, payload) {
        var id = payload.id;
        await axios
          .get(`admin/worker.php?action=getsingleworker&id=${id}`)
          .then((res) => {
            if (!res.data.successmsg || res.data.error) {
              const error = new Error(error || "Something Wrong try again later.");
              throw error;
            } else {
              var data = res.data.details;
              context.commit("setSingleWorker", { data: data });
            }
          })
          .catch((error) => {
            console.log(error);
            error = new Error(error || "Failed to authenticate.");
            throw error;
          });
      },
      async updateWorker(context, payload) {
        var data = new FormData();
        data.append("id", payload.id);
        data.append("name", payload.name);
        data.append("phone", payload.phone);
        data.append("email", payload.email);
        data.append("password", payload.password);
        data.append("active", payload.active);
        await axios
          .post(`admin/worker.php?action=updateworker`, data)
          .then((res) => {
            if (!res.data.successmsg || res.data.error) {
              const error = new Error(error || "Something Wrong try again later.");
              throw error;
            }
          })
          .catch((error) => {
            console.log(error);
            error = new Error(error || "Failed to authenticate.");
            throw error;
          });
      },
      async getProjectOption(context,payload){
        var id=payload.id;
        await  axios.get(`admin/worker.php?action=getUserProjectOption&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var projectdata= res.data.projectdata;
                context.commit('setProjectOption',{data:projectdata})
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async getOption(context,payload){
      var id=payload.id;
      await  axios.get(`admin/worker.php?action=getoption&id=${id}`)
      .then((res) => {
          if(!res.data.successmsg || res.data.error){
              const error = new Error(error ||'Something Wrong try again later.');
              throw error;
          }else{
              var itemdata= res.data.itemdata;
              var projectdata= res.data.projectdata;
              context.commit('setItemOption',{data:itemdata});
              context.commit('setProjectOption',{data:projectdata})
          }
      })
      .catch((error) => {console.log(error); 
           error = new Error(error ||'Failed to authenticate.');
          throw error;      
      })
  },
};