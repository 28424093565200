import axios from 'axios';
export default{
    
    async getPayment(context,payload){
        var pn=payload.page_no;
        var option=payload.option;
        await  axios.get(`admin/payment.php?action=getPayment&option_id=${option}&page_no=${pn}`)
        .then((res) => {

                var data= res.data.details;
                var pagination=res.data.pagination;
                var pagination_text = res.data.pagination_text;
                var active= res.data.active;
                context.commit('setPayment',{data:data,pagination:pagination,pagination_text:pagination_text,active:active})
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async getUserPayment(context,payload){
        var pn=payload.page_no;
        var option=payload.option;
        var date=payload.date;
        var userid=localStorage.getItem("userId");

        await  axios.get(`admin/payment.php?action=getUserPayment&option_id=${option}&page_no=${pn}&date=${date}&userId=${userid}`)
        .then((res) => {

                var data= res.data.details;
                var pagination=res.data.pagination;
                var pagination_text = res.data.pagination_text;
                var active= res.data.active;
                context.commit('setPayment',{data:data,pagination:pagination,pagination_text:pagination_text,active:active})
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async getOption(context,payload){
        var id=payload.id;
        await  axios.get(`admin/payment.php?action=getoption&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var clientdata= res.data.clientdata;
                context.commit('setClientOption',{data:clientdata});

            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async getProjectOption(context,payload){
        var id=payload.id;
        await  axios.get(`admin/payment.php?action=getProjectOption&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var projectdata= res.data.projectdata;
                context.commit('setProjectOption',{data:projectdata})
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async addPayment(context,payload){
        var userid=localStorage.getItem("userId");
        var data = new FormData();
        data.append('payment_date',payload.date);
        data.append('client_name',payload.client);
        data.append('project_id',payload.project);
        data.append('bill_amount',payload.bill_amount);
        data.append('userid',userid);
        data.append('payment_detail',payload.payment_detail);
        data.append('file',payload.file);
        await  axios.post(`admin/payment.php?action=addPayment`,data)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
        console.log(userid)
        
    },
    async singleGetPayemnt(context,payload){
        var id=payload.id
        await  axios.get(`admin/payment.php?action=getsinglePayment&id=${id}`)
        .then((res) => {
            console.log(res.data);
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var data= res.data.details;
                context.commit('setSinglePayment',{data:data})                
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async updatePayment(context,payload){
        //var userid=localStorage.getItem("userId");
        var data = new FormData();
            data.append('id',payload.id); 
            data.append('payment_date',payload.date);
            data.append('client_name',payload.client);
            data.append('project_id',payload.project);
            data.append('bill_amount',payload.bill_amount);
            data.append('userid',payload.userid);
            data.append('payment_detail',payload.payment_detail);
            data.append('file',payload.file);
            data.append('file_name',payload.file_name);
           await  axios.post(`admin/payment.php?action=updatePayment`,data)
           .then((res) => {
               console.log(res.data);
               if(!res.data.successmsg || res.data.error){
                   const error = new Error(error ||'Something Wrong try again later.');
                   throw error;
               }
           })
           .catch((error) => {console.log(error); 
                error = new Error(error ||'Failed to authenticate.');
               throw error;      
           })
           
       },
       async additem(context,payload){
        var data = new FormData();
           data.append('item',payload.item);
           await  axios.post(`admin/purchase.php?action=additem`,data)
           .then((res) => {

               if(!res.data.successmsg || res.data.error){
                   const error = new Error(error ||'Something Wrong try again later.');
                   throw error;
               }
           })
           .catch((error) => {console.log(error); 
                error = new Error(error ||'Failed to authenticate.');
               throw error;      
           })
           
       },
       async DeletePayemnt(context,payload){
        var id=payload.id;
        await  axios.get(`admin/payment.php?action=delPayment&id=${id}`)
        .then((res) => {
            console.log(res);
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }
        })
        .catch((error) => {console.log(error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
}