export default{
    getworker(state){
       // console.log(state.workers)
        state.worker=state.workers;
       // console.log(state.worker)
       // return state.worker;
    },
    getActive(state){
        return state.active;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    singleworker(state){
        return state.singleworker;
    },getProjectOption(state){
        return state.projectOption;
    },
    getItemOption(state){
        return state.itemtOption;
    },
}