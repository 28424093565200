export default{
    getPurchse(state){
        return state.purchase;
    },
    getActive(state){
        return state.active;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    getSinglePurchase(state){
        return state.singlepurchase;
    },
    getItemOption(state){
        return state.itemtOption;
    },
    getProjectOption(state){
        return state.projectOption;
    },getDelMessage(state){
        return state.delMessage;
    }

}