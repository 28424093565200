import axios from 'axios';
export default{
   async addProject(context,payload){
       var data = new FormData();
        data.append('project_name',payload.name);
        data.append('party_id',payload.client_id);
        data.append('start_date',payload.st_date);
        data.append('end_date',payload.end_date);
        await  axios.post(`admin/project.php?action=addproject`,data)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }
        })
        .catch((error) => {console.log('Add Project Error  :- '+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
        
    },
    async getProject(context,payload){
        var pn=payload.page_no;
        var option=payload.option;
        await  axios.get(`admin/project.php?action=getProject&option_id=${option}&page_no=${pn}`)
        .then((res) => {

                var data= res.data.details;
                var pagination=res.data.pagination;
                var pagination_text = res.data.pagination_text;
                var active= res.data.active;
                context.commit('setProject',{data:data,pagination:pagination,pagination_text:pagination_text,active:active})
        })
        .catch((error) => {console.log('Get List Error :- '+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async getOption(context,payload){
        var id=payload.id;
        await  axios.get(`admin/project.php?action=getoption&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var data= res.data.details;
                context.commit('setClientOption',{data:data})
            }
        })
        .catch((error) => {console.log("Project Get Option Error : - "+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async singleGetProject(context,payload){
        var id=payload.id
        await  axios.get(`admin/project.php?action=getsingleproject&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                var data= res.data.details;
                context.commit('setSingleProject',{data:data})                
            }
        })
        .catch((error) => {console.log(' Singele Project Get Detail Error :- '+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    async updateproject(context,payload){

        var data = new FormData();
        data.append('id',payload.id);
        data.append('project_name',payload.name);
        data.append('party_id',payload.client_id);
        data.append('start_date',payload.st_date);
        data.append('end_date',payload.end_date);
        await  axios.post(`admin/project.php?action=updateproject`,data)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }
            
        })
        .catch((error) => {console.log('Update Project Error :- '+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })

    },
    async DeleteProject(context,payload){
        var id=payload.id
        await  axios.get(`admin/project.php?action=delProject&id=${id}`)
        .then((res) => {
            if(!res.data.successmsg || res.data.error){
                const error = new Error(error ||'Something Wrong try again later.');
                throw error;
            }else{
                context.commit('delMessage',{data:'Deleted Successfully'})                 
            }
        })
        .catch((error) => {console.log(' Singele Project Get Detail Error :- '+error); 
             error = new Error(error ||'Failed to authenticate.');
            throw error;      
        })
    },
    
}