
export default{
    setUser(state,payload){
        state.userId=payload.id;
        state.dbRole=payload.role;
        state.uname=payload.userName;   
    },
    setAutoLogout(state){
        state.didAutoLogout=true;
    }
};