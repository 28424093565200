import axios from 'axios';
export default{
    async getpdf(context,payload){
        var client_option='';
        var page_name=payload.page_name;
        var year='';
        var month='';
    var gst_type='';
    var gst_type_2='';
    var platform_option='';
    var dt_from='';
    var dt_to='';
         await  axios.get(`pdf_download.php?&client_option=${client_option}&page_name=${page_name}&year=${year}&month=${month}&gst_type=${gst_type}&gst_type_2=${gst_type_2}&platform_option=${platform_option}&dt_from=${dt_from}&dt_to=${dt_to}`)
         .then((res) => {
             /*if(!res.data.successmsg || res.data.error){
                 const error = new Error(error ||'Something Wrong try again later.');
                 throw error;
             }*/
             console.log(res)
         })
         .catch((error) => {console.log('pdf Error  :- '+error); 
              error = new Error(error ||'Failed to authenticate.');
             throw error;      
         })
         
     },
};