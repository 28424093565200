import axios from "axios";
export default {
  async addclient(context, payload) {
    var data = new FormData();
    data.append("name", payload.name);
    data.append("contact", payload.contact);
    data.append("phone", payload.phone);
    data.append("whatsapp", payload.whatsapp);
    data.append("email", payload.email);
    data.append("address", payload.address);
    await axios
      .post(`admin/client.php?action=addclient`, data)
      .then((res) => {
        if (!res.data.successmsg || res.data.error) {
          const error = new Error("Something Wrong try again later.");
          throw error;
        }
      })
      .catch((error) => {
        error = new Error(error || "Failed to authenticate.");
        throw error;
      });
  },
  async getClient(context, payload) {
    var pn = payload.page_no;
    var option = payload.option;
    await axios
      .get(
        `admin/client.php?action=getclient&option_id=${option}&page_no=${pn}`
      )
      .then((res) => {
        
          var data = res.data.details;
          var pagination = res.data.pagination;
          var pagination_text = res.data.pagination_text;
          var active= res.data.active;
          context.commit("setClient", {
            data: data,
            pagination: pagination,
            pagination_text: pagination_text,
            active:active
          });
        
      })
      .catch((error) => {
        error = new Error(error || "Failed to authenticate.");
        throw error;
      });
  },
  async singleGetClient(context, payload) {
    var id = payload.id;
    await axios
      .get(`admin/client.php?action=getsingleclient&id=${id}`)
      .then((res) => {
        if (!res.data.successmsg || res.data.error) {
          const error = new Error(error || "Something Wrong try again later.");
          throw error;
        } else {
          var data = res.data.details;
          context.commit("setSingleClient", { data: data });
        }
      })
      .catch((error) => {
        console.log(error);
        error = new Error(error || "Failed to authenticate.");
        throw error;
      });
  },
  async updateClient(context, payload) {
    var data = new FormData();
    data.append("id", payload.id);
    data.append("name", payload.name);
    data.append("contact", payload.contact);
    data.append("phone", payload.phone);
    data.append("whatsapp", payload.whatsapp);
    data.append("email", payload.email);
    data.append("address", payload.address);
    await axios
      .post(`admin/client.php?action=updateclient`, data)
      .then((res) => {
        if (!res.data.successmsg || res.data.error) {
          const error = new Error(error || "Something Wrong try again later.");
          throw error;
        }
      })
      .catch((error) => {
        console.log(error);
        error = new Error(error || "Failed to authenticate.");
        throw error;
      });
  },
};
