<template>
    <footer class="footer">
        &copy; <router-link to="https://www.zeroerrorzone.com/">Zero Error Zone</router-link>
    </footer>
</template>
<style scoped>
.footer{
    position: fixed;
    bottom: 0;
    text-align: center;
    background: var(--bluenavy);
    width: 100%;
    padding: 0.2rem;
    color:white;
}
.footer a{
    color: white;
}
</style>