import { createStore } from 'vuex'
import authModule from "./Auth/index.js";
import adminModule from "./admin/index.js";
import projectModule from "./project/index.js";
import purchaseModule from "./purchase/index.js";
import paymentModule from "./payment/index.js";
import workerModule from "./worker/index.js";
import pdfModule from "./pdf/index.js";
import reportModule from "./report/index.js";

const store = createStore({
    modules: {
      a: authModule,
      admin:adminModule,
      project:projectModule,
      purchase:purchaseModule,
      payment:paymentModule,
      worker:workerModule,
      pdf:pdfModule,
      report:reportModule,

    }
  })
export default store;