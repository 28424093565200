import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default{
    namespaced:true,
    state(){
        return{
            projects:[],
            pagination:[],
            paginationText:[],
            singleproject:[],
            clientOption:[],
            delMessage:null,
            active:null,
        }
    },
    getters,
    mutations,
    actions,
}