<template>
  <router-view></router-view>
  <the-footer></the-footer>
</template>
<script>
import TheFooter from './components/layout/TheFooter.vue';
export default {
  components:{
    TheFooter
  }
}
</script>
<style>
:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --lightblue: #A1D6E2;
  --darkblue : #00008B;
  --bluenavy : #1b3d66;
}
body{
  font-weight: bold !important;
}
     h2{
        padding: 1rem;
    }
    h4,h1,h2{
      color:#000 !important;
    }
    thead{
        background: var(--bluenavy);
        color:white;
    }
    .border{
        border:2px solid var(--bluenavy) !important;
    }
    .btn{
      background:var(--bluenavy) !important;
      color:#fff !important;
      border: 2px solid #BCBABE !important;
    }
    .active .btn{
      background: #fff !important;
      color:var(--bluenavy) !important;
      border: 2px solid #BCBABE !important;
    }
    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:80px;
        z-index:1000;
        background-color:var(--bluenavy);
        color:#FFF;
        border-radius:50px;
        text-align:center;
        box-shadow: 2px 2px 3px #999;
        font-weight:bold;
    }
    .float:hover{
        cursor: pointer;
    }
    .text-right{
        text-align: right;
    }
    label span {
  color: red;
}
.invalid {
  color: red;
}
input.invalid:focus,
input.invalid:active,
textarea.invalid:focus,
textarea.invalid:active,
textarea.invalid {
  border: 2px solid red;
  outline: 2px solid red;
}
        .msg-enter-from{
  opacity: 0;
  transform: translateY(-30px);
}
.msg-leave-to{
  opacity: 0;
  transform: translateY(30px);
}
.msg-enter-active{
  transition: all 0.3s ease-out;
}
.msg-leave-active{
  transition: all 0.3s ease-in;
}
.msg-enter-to,.route-leave-from{
  opacity: 1;
  transform: translateY(0);
}
body{
  overflow-y:auto !important ;
}
form{
  width: 100% !important;
}
td img{
  height:100px;
  width:100px;
}
.table_div{
  overflow-x: auto;
}
.table_div table{
  width: 100%;
  
}
th{
  min-width:100px !important;
}
@media only screen and (max-width: 768px) {
  td img{
  height:50px;
  width:50px;
}
th{
  min-width:130px !important;
}
}
</style>