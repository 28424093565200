export  default{
    setProjectReport(state,payload){
       
        state.ProjectReport=payload.data;
    },
    setTotalExpence(state,payload){
        state.totalexpence=payload.data;
    },setTotalRecive(state,payload){
        state.totalRecive=payload.data;
    },
    setProjectOption(state,payload){
        state.projectOption=payload.dataop;
        console.log(state.projectOption);
    },
};