import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default{
    namespaced:true,
    state(){
        return{
            purchase:[],
            pagination:[],
            paginationText:[],
            singlepurchase:[],
            projectOption:[],
            itemtOption:[],
            delMessage:null,
            active:null,
        }
    },
    getters,
    mutations,
    actions,
}