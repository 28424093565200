import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default{
    namespaced:true,
    state(){
        return{
            payment:[],
            pagination:[],
            paginationText:[],
            singlepayment:[],
            projectOption:[],
            clientOption:[],
            active:null,
        }
    },
    getters,
    mutations,
    actions,
}