export default{
    getProjectReport(state){
        return state.ProjectReport;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    TotalExpence(state){
        return state.totalexpence;
    },
    TotalRecive(state){
        return state.totalRecive;
    },
    getProjectOption(state){
        console.log(state.projectOption);
        console.log('recive '+state.projectOption);
        return state.projectOption;
    }
}