export default{
    getClients(state){
        return state.clients;
    },
    getActive(state){
        return state.active;
    },
    getpagination(state){
        return state.pagination;
    },
    getpagination_text(state){
        return state.paginationText;
    },
    getSingleClient(state){
        return state.singleClinet;
    },
}