<template>
    <header>
        <div class="row">
          <nav class="navbar bg-light fixed-top m-0 p-0">
            <div class="container-fluid">
              <a class="navbar-brand" href="#"><img src="@/assets/dec.png"></a>
              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#Navbar" aria-controls="Navbar">
                <!--<span class="navbar-toggler-icon"></span>-->
                <b class='fas fa-bars'></b>
              </button>
              <div class="NavbarMenu  offcanvas offcanvas-end" tabindex="-1" id="Navbar" aria-labelledby="MenuLabel">
                <div class="offcanvas-header">
                  <h5 class="offcanvas-title" id="MenuLabel"><img src="@/assets/dec.png"></h5>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                  <ul class="navbar-nav justify-content-end flex-grow-1 pe-3" v-if="getDetail!=3">
                    <li class="nav-item">
                      <router-link class="nav-link active" aria-current="page" to="/dash">Home</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/Client">Clients</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/Project">Projects</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/Purchase">Purchase</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/payment">Payments</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/worker">Worker Create/Suspend</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/report">Report</router-link>
                    </li>
                    <li>
                      <base-button @click="logout" mode="btn">  Logout</base-button>
                    </li>
                  </ul>
                  <ul class="navbar-nav justify-content-end flex-grow-1 pe-3" v-else>
                    <!--<li class="nav-item">
                      <router-link class="nav-link active" aria-current="page" to="/dash">Home</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/purchase">Purchase</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link" to="/payment">Payments</router-link>
                    </li>-->
                    <li>
                      <base-button @click="logout" mode="btn">  Logout</base-button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
    </header>
    <div class="row mt-5 pt-5">
          <h4 class="text-right px-5 py-2"> WELCOME Hi ! {{getname}}</h4>
    </div>
     
</template>
<script>
export default {
    methods:{
      logout(){
        this.$store.dispatch('logout')
        this.$router.push('/')
      },
    },
    computed:{
    isAuthenticate(){
        return this.$store.getters.isAuthenticate;
    },
    getDetail(){
        return this.$store.getters.getRole;
    },
    getname(){
        return this.$store.getters.getUserName;
    }
  },created(){  
    this.$store.dispatch('tryLogin')    
  }
}
</script>
<style  scoped>
    header,.bg-light{  
        background: white !important;
        padding:0px;
        border-bottom:2px solid var(--bluenavy);
    }
    .text-right{
        text-align: right;
    }
    .navbar-toggler{
      color:var(--bluenavy) !important;
      ;
    }
    .navbar-toggler-icon{
      background: #fff;
      color:#000 !important;
    }
    .navbar-nav{
      text-align: center;
      background-color: var(--bluenavy);
      font-weight: bold;
      font-size:18px;
    }
    .navbar-nav li a{
      color:#fff;
    }
    .navbar-nav li a:hover{
      color: var(--bluenavy);;
      background-color: #fff;
      border:5px solid var(--bluenavy);
    }
</style>