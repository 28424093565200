export default{
    isAuthenticate(state){
        return state.userId;
    },
    getRole(state){
        return state.dbRole;
    },
    getUserName(state){
        return state.uname;
    }
};